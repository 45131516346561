import * as React from "react";
import moment from "moment";
import { ISurveyBoxProps } from "./ISurveyBoxProps.tsx";
import { ISurveyBoxState } from "./ISurveyBoxState.tsx";

export class SurveyBox extends React.Component<
  ISurveyBoxProps,
  ISurveyBoxState,
  {}
> {
  public constructor(props: ISurveyBoxProps) {
    super(props);
  }

  public render(): React.ReactElement<ISurveyBoxProps> {
    const { survey } = this.props;

    const title: string = this.props.surveyService.GetLanguageValue(
      survey.title,
      this.props.selectedLanguageCode
    );
    const periodStart: string = moment(survey.periodStart).format("DD/MM/YYYY");
    const periodEnd: string = moment(survey.periodEnd).format("DD/MM/YYYY");
    let style: string = "LinkTheme";
    if (survey.korianTheme && survey.korianTheme !== null && survey.korianTheme !== "" ){
      style += survey.korianTheme;
    }
    else{
      style += "Orange";
    }

    return (
      <div>
        <div className="imgArea">
          <img src={survey.bannerUrl} />
        </div>
        <div className={style}>
          <div className="KorianRectLinkContainer">
            <div className="KorianRectLink">
              <div className="contentline title">{title}</div>
              <div className="contentline">
                {periodStart} - {periodEnd}
              </div>
              <div className="contentline">
                <a href={survey.linkUrl} target="_new" className="button">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                  <span className="label">Start</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
