import axios from 'axios';
import { IKorianCloudItem, IMuiValues } from "./IKorianCloudItem";

export class SurveyService {
  public GetLanguageValue(
    data: IMuiValues,
    selectedLanguageCode: string
  ): string {
    let result: string = data.defaultValue;
    if (data.values && data.values !== null) {
      if (
        data.values.filter((x) => x.local === selectedLanguageCode).length >
          0 &&
        data.values.filter((x) => x.local === selectedLanguageCode)[0].value !==
          ""
      ) {
        result = data.values.filter((x) => x.local === selectedLanguageCode)[0]
          .value;
      }
    }
    return result;
  }
  public GetDataFromAPI():  Promise<IKorianCloudItem[]> {
    let endPoint: string =
      "https://korianbeneluxpublicapi.azurewebsites.net/api/GetPublicSurveys?code=pfRr9f7OzjhM3u5FiZv7rn0MBmiHQM_YJu-FYYiu_b5MAzFuYszqRg==";
      const body: any = {};

    return new Promise<IKorianCloudItem[]>((resolve) => {
      axios.get(endPoint, body).then((apiResult) => {
        resolve(apiResult.data.data);
      });
    });
  }
}
