import * as React from "react";
import "./MainApp.css";
import { IMainAppProps } from "./IMainAppProps";
import { IMainAppState } from "./IMainAppState";
import { IMuiValues, IKorianCloudItem } from "./IKorianCloudItem";
import { SurveyService } from "./SurveyService.tsx";
import { SurveyBox } from "../SurveyBox/SurveyBox.tsx";

export class MainApp extends React.Component<IMainAppProps, IMainAppState, {}> {
  private _dataSiteTitle: IMuiValues;
  private _dataService: SurveyService;

  public constructor(props: IMainAppProps) {
    super(props);

    this._dataService = new SurveyService();

    this.state = {
      selectedLanguageCode: navigator.language.substring(0, 2),
      surveys: [],
      isLoading: true,
    };

    this._dataSiteTitle = {
      defaultValue: "Tevredenheidsbevraging",
      values: [
        {
          local: "nl",
          value: "Tevredenheidsbevraging",
        },
        {
          local: "fr",
          value: "Enquête de satisfaction",
        },
        {
          local: "de",
          value: "Umfrage zur Zufriedenheit",
        },
      ],
    };
  }

  public componentDidMount(): void {
    this._dataService.GetDataFromAPI().then((results) => {
      this.setState({
        surveys: results,
        isLoading: false,
      });
    });
  }

  public render(): React.ReactElement<IMainAppProps> {
    const { surveys, isLoading } = this.state;
    const siteTitle: string = this._dataService.GetLanguageValue(this._dataSiteTitle, this.state.selectedLanguageCode);

    return (
      <div>
        <nav className="navbar navbar-light bg-light justify-content-between">
          <a className="navbar-brand" href="#">
            <img src="/assets/clariane-csatisfaction-logo.png" width="30" height="30" className="d-inline-block align-top" alt="" />
            &nbsp;Korian - Satisfaction
          </a>
          <div className="navbarActions">
            <div onClick={() => this.setState({ selectedLanguageCode: "nl" })} title="Nederlands">
              <img src="/assets/flag-be.png" height={24} />
            </div>
            <div onClick={() => this.setState({ selectedLanguageCode: "fr" })} title="Français">
              <img src="/assets/flag-fr.png" height={24} />
            </div>
            <div onClick={() => this.setState({ selectedLanguageCode: "de" })} title="Deutsch">
              <img src="/assets/flag-de.png" height={24} />
            </div>
          </div>
        </nav>
        <div>
          <div className="row">
            <div className="col">
              <div className="ac">
                <div>
                  <img src="/assets/clariane-csatisfaction.png" width="240px" />
                </div>
                <div>
                  <h1>{siteTitle}</h1>
                </div>
              </div>
            </div>
          </div>
          {isLoading ? <div className="row justify-content-center blocks">Loading...</div> : null}
          {isLoading === false && surveys.length === 0 ? <div className="row justify-content-center blocks">There are currently no active surveys</div> : null}
          <div className="row justify-content-center blocks">
            {surveys.map((survey: IKorianCloudItem, index) => {
              return <SurveyBox surveyService={this._dataService} survey={survey} selectedLanguageCode={this.state.selectedLanguageCode} />;
            })}
          </div>
          <div className="row">
            <div className="col">
              <div className="ac">
                <a href="https://www.korian.be/" title="Official Korian BE Website">
                  <img src="assets/korian-logo.png" className="korianLogo" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
