import React from 'react';
import ReactDOM from 'react-dom';
import { MainApp } from './components/MainApp/MainApp.tsx';

ReactDOM.render(
  <React.StrictMode>
      <MainApp/>
  </React.StrictMode>,
  document.getElementById('root')
);

